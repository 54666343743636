<template>
  <div></div>
</template>

<script>
import { Toast } from 'vant'
import { getUserInfo } from '@/api/wx.js'
export default {
  data() {
    return {}
  },
  mounted() {
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0
    })
    localStorage.setItem('url', window.V3url + '/weixinlocal/index.html')
    sessionStorage.setItem('url', window.V3url + '/weixinlocal/index.html')
    this.getUserInfo()
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      getUserInfo({ usertoken: this.$route.query.usertoken }).then(res => {
        localStorage.setItem('userInfo', JSON.stringify(res.data[0]))
        sessionStorage.setItem('userInfo', JSON.stringify(res.data[0]))
        localStorage.setItem('v8_login_pwd', res.data[0].pwd)
        localStorage.setItem('v8_login_username', res.data[0].username)
        this.goto()
      })
    },
    // 跳转
    goto() {
      /*
				parmams格式【参数名称1_参数值1@参数名称2_参数值2】:empid_19%khplanid_12........
      */
      let past = this.$route.query.path // 跳转路由地址
      if (this.$route.query.params) {
        // 传入参数
        const params = this.$route.query.params
        let arr = params.indexOf('@') > -1 ? params.split('@') : [params]
        arr.forEach((e, i) => {
          const dl = i === 0 ? '?' : '&'
          const a = e.split('_')
          past += dl + a[0] + '=' + a[1]
        })
      }
      Toast.clear()
      this.$router.push('/' + past)
    }
  }
}
</script>

<style>
</style>